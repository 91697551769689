<template>
  <div id="how-it-works" class="position-relative overflow-hidden">
    <div class="container space-2">
      <div class="w-md-85 w-lg-65 text-center mx-md-auto mb-5">
        <span class="d-block small font-weight-bold text-cap mb-2">HOW IT WORKS</span>
        <h2>Transforming API request and response headers</h2>
        <p>Unlock the value of data and expand your reach to customers in a secure manner.</p>
      </div>
      <div class="row align-items-center mb-9">
        <div class="col-12 col-md-6 mb-2 mb-md-0">
          <figure class="max-w-8rem mb-3">
            <img class="img-fluid" src="@/assets/svg/illustrations/lego-1.svg">
          </figure>
          <h3 class="mb-3">Connect your API on FabriXAPI</h3>
          <p>FabriXAPI supports both OpenAPI Specification (OAS) and Markdown (MD) file formats. Upload them in our online editor or on our CI/CD pipeline, we'll take care everything else! The API will then be listed in your API Portal in minutes.</p>
        </div>
        <div class="col-12 col-md-6">
          <div class="w-80 w-md-100 w-lg-75 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/transformations/transformations-feature-1.svg" alt="Connect your API on FabriXAPI">
          </div>
        </div>
      </div>
      <div class="row align-items-center mb-9">
        <div class="col-12 col-md-6 mb-2 mb-md-0 order-md-2">
          <figure class="max-w-8rem mb-3">
            <img class="img-fluid" src="@/assets/svg/illustrations/lego-2.svg">
          </figure>
          <h3 class="mb-3">Configure the API Request or Response Headers to be transformed</h3>
          <p>You can then configure the API transformation policy in the online editor. On FabriXAPI, you can:</p>
          <ul>
            <li>Add header parameters in requests and responses</li>
            <li>Add query parameters in requests</li>
          </ul>
          <p>You can make use of API Transformations to apply designated internal API keys, add metadata for upstream services and configure rate limit policy for your APIs, etc.</p>
        </div>
        <div class="col-12 col-md-6 order-md-1">
          <div class="w-80 w-md-100 w-lg-75 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/transformations/transformations-feature-2.svg" alt="Configure the API Request or Response Headers to be transformed">
          </div>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-12 col-md-6 mb-2 mb-md-0">
          <figure class="max-w-8rem mb-3">
            <img class="img-fluid" src="@/assets/svg/illustrations/lego-3.svg">
          </figure>
          <h3 class="mb-3">Reference Use Cases</h3>
          <p>The request and response transformations have the following capabilities:</p>
          <ul>
            <li>Hide sensitive information such as internal API Keys</li>
            <li>Inject metadata for specific backend control</li>
            <li>Integrate with API Analytics Toolings</li>
          </ul>
        </div>
        <div class="col-12 col-md-6">
          <div class="w-80 w-md-100 w-lg-75 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/community/community-feature-3.svg" alt="Grant API Access to the API Hub Community">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HowItWorks'
}
</script>

<style lang="scss" scoped>
</style>
